<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="input-group add-on">
        <input class="form-control"
               id="srch-term"
               name="srch-term"
               type="text"
               placeholder="Search"
               autocomplete="off"
               v-model="filterText"
               @keyup.enter="doFilter">
        <div class="input-group-append">
          <button class="btn btn-primary"
                  type="submit"
                  @click="doFilter">
            <i class="fas fa-search"></i>
          </button>
        </div>
        <button class="btn btn-outline-primary ml-2"
                @click="resetFilter">
          <i class="fas fa-sync-alt"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        filterText : '',
      };
    },
    methods : {

      /**
       * Filter action
       */
      doFilter() {
        this.$events.fire('filter-set-category', this.filterText);
      },

      /**
       * Reset Filter
       */
      resetFilter() {
        this.filterText = '';  // clear the text in text input
        this.$events.fire('filter-reset-category');
      },
    },
    components : {},
  };
</script>

<style lang="scss" scoped>
  @import '../../../assets/scss/components/admin/media/filter-bar';
</style>